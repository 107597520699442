import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import classnames from 'classnames/bind';

import { useDimensions } from '../../hooks';
import babyYoda from '../../static/images/baby-yoda.png';

import styles from './NotFound.module.scss';
const cx = classnames.bind(styles);

const NotFound = () => {
  const [isFontLoading, setIsFontLoading] = useState(true);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const { windowWidth } = useDimensions();

  const content = useMemo(() => ({
    width: windowWidth * 0.3,
    height: windowWidth * 0.12,
  }), [windowWidth]);

  useEffect(() => {
    (async () => {
      try {
        await document.fonts.ready;
      } finally {
        setIsFontLoading(false);
      }
    })();
  }, []);

  const cancelImageLoader = useCallback(() => {
    setIsImageLoading(false);
  }, []);

  return (
    <>
      {(isFontLoading || isImageLoading) && (
        <ContentLoader
          speed={2}
          width={content.width}
          height={content.height}
          backgroundColor="#222"
          foregroundColor="#f7f164"
          style={{ position: 'absolute' }}
        >
          <rect x={0} y={0} rx="4" ry="4" width={content.width} height={content.height} />
        </ContentLoader>
      )}
      <div className={cx('notFound')}>
        <span>4</span>
        <img alt="0" src={babyYoda} onLoad={cancelImageLoader} />
        <span>4</span>
      </div>
    </>
  );
};

export default NotFound;
